import React, { memo } from 'react'
import { iconLock, iconUnlocked } from '../../utils/icons'

const ListItemProject = ({ hasNda, isUnlocked, clientName, name, tags }) => (
  <>
    <div
      className={`list__client mb-0 ft-truncat ${
        !hasNda ? 'list__client-public' : ''
      }`}
    >
      {hasNda && (
        <span className="mr-2">{isUnlocked ? iconUnlocked : iconLock}</span>
      )}{' '}
      {clientName || ''}
    </div>
    <div className="list__name ft-default-m-small ft-truncat">{name} </div>
    <div className="list__tags ft-default-m-small ft-truncat c-alpha-5">
      {tags.join(' + ')}
    </div>
  </>
)

export default memo(ListItemProject)
