import React, { useMemo, useState, useCallback, useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import queryString from "query-string";
import ClassicLayout from '../components/Layouts/ClassicLayout'
import ListProjects from '../components/ListProjects'
import { graphql } from 'gatsby'
import { internationalRoutes, routeNames } from '../utils/helpers/routes'
import { addTrailingSlash, checkEntryPage, isBrowser, throttle } from '../utils/helpers/utils'
import SearchProjects from '../components/SearchProjects'
import { defaultLanguage } from '../utils/constants/global'
import { setEntry, animateEntry, animateHideList, animateShowList, setVisibleEntry } from '../utils/page-animations/projects';
import { getPrismicEdges } from '../utils/helpers/transformers';

import ScrollTrigger from 'gsap/ScrollTrigger'
import { HistoryContext } from '../utils/hooks/History';
import useAnimationState from '../utils/hooks/useAnimationState';

const Projects = (props) => {
  const { data, pageContext } = props
  const { routeName, language } = pageContext

  // INTN
  const { t } = useTranslation(['projects'])
  const localizedRouteProjects = useMemo(() => internationalRoutes[routeName].langs[language], [routeName, language]);

  // CONTEXTS
  const { replaceRouteFromHistory } = useContext(HistoryContext);

  // ANIMATION
  const animationState = useAnimationState({ routeName })
  const $hasRunEntryAnimation = useRef(false)
  const $hasRunAnimation = useRef(false)

  // STATES
  const [searchKeywords, setSearchKeywords] = useState(() => {
    if (!isBrowser) return ''
    const parsed = queryString.parse(window.location.search);
    return parsed.s || ''
  })
  const [yearFilter, setYearFilter] = useState(() => {
    if (!isBrowser) return ''
    const parsed = queryString.parse(window.location.search);
    return parsed.year || ''
  }) 
  const [industryFilter, setIndustryFilter] = useState(() => {
    if (!isBrowser) return ''
    const parsed = queryString.parse(window.location.search);
    return parsed.industry || ''
  })

  // DATA
  const canShowNDA = useMemo(() => checkEntryPage(routeName, routeNames.lockedProjects), [routeName])
  const hasYearFilter = useMemo(() => yearFilter.length > 0, [yearFilter])
  const hasIndustryFilter = useMemo(() => industryFilter.length > 0, [industryFilter])
  const projects = useMemo(() => getPrismicEdges(data, 'prismicProjects'), [data])

  // HELPERS
  const getMatchYear = useCallback(
    (project) => {
      return hasYearFilter ? project.year === yearFilter : true
    },
    [hasYearFilter, yearFilter]
  )

  const getMatchIndustry = useCallback(
    (project) => {
      const industry = project.industry && project.industry.document ? project.industry.document.uid : null
      return hasIndustryFilter ? (industry && industry.length > 0 ? industry === industryFilter : false) : true
    },
    [industryFilter, hasIndustryFilter]
  )

  const getMatchSearch = useCallback(
    (project) => {
      const hasSearchKeywords = searchKeywords.length > 0
      const search = `${searchKeywords}`.toLowerCase().trim()
      const projectName = project.name && project.name && project.name.text ? project.name.text : ''
      const projectClientName =
        project.client && project.client.document && project.client.document.data && project.client.document.data.name
          ? project.client.document.data.name.text
          : ''
      const projectTags = project.tags ? project.tags.text : ''

      return hasSearchKeywords
        ? projectName.toLowerCase().includes(search) ||
        projectClientName.toLowerCase().includes(search) ||
        projectTags.toLowerCase().includes(search)
        : true
    },
    [searchKeywords]
  )

  const industries = useMemo(
    () =>
      projects && projects.length
        ? projects
            // get only uid and name
            .map((p) => ({
              name: p.data.industry.document && p.data.industry.document.data.name.text,
              uid: p.data.industry.document && p.data.industry.document.uid,
            }))
            // get count
            .reduce((ar, obj) => {
              let bool = false
              if (!obj.name) {
                return ar
              }

              if (!ar) {
                ar = []
              }
              ar.forEach((a) => {
                if (a.name === obj.name) {
                  a.count++
                  bool = true
                }
              })
              if (!bool) {
                obj.count = 1
                ar.push(obj)
              }
              return ar
            }, [])
        : [],
    [projects]
  )

  const filteredProjectsByYears = useMemo(() => {
    return projects
      .filter((item) => {
        const project = item && item && item.data ? item.data : null
        if (!project) {
          return false
        }

        const matchIndustry = getMatchIndustry(project)
        const matchSearch = getMatchSearch(project)
        const matchYear = getMatchYear(project)
        
        return matchYear && matchIndustry && matchSearch
      })
      .reduce((acc, { data, uid }) => {
        if (!data || !data.name.text) return acc;
        const projectUid = uid && uid.length > 0 ? uid : null
        if (!projectUid) return acc;
        const tags = data.tags.raw && data.tags.raw.length > 0 ? data.tags.raw.map((tag) => tag.text) : []
        const client = data?.client?.document?.data || null

        acc.push({
          uid: projectUid,
          tags,
          client,
          year: data.year,
          name: data.name,
          has_page: data.has_page,
          has_nda: data.has_nda
        })
        return acc;
      }, [])
      .reduce((acc, data) => {
        const found = acc.find((a) => {
          return a.year === data.year
        })

        if (!found) {
          acc.push({ year: data.year, filteredProjects: [data] })
        } else {
          found.filteredProjects.push(data)
        }
        return acc
      }, [])
  }, [projects, getMatchIndustry, getMatchSearch, getMatchYear])

  const listProjectsAnimation = useMemo(() => ({ trigger: '.filter__header', start: 'top center' }), [])

  // HANDLERS
  const updateLocation = useCallback(() => {
    const params = []

    if (searchKeywords.length) {
      params.push(`s=${encodeURIComponent(searchKeywords)}`)
    }

    if (yearFilter.length) {
      params.push(`year=${yearFilter}`)
    }

    if (industryFilter.length) {
      params.push(`industry=${industryFilter}`)
    }
    
    const prefix = language === defaultLanguage ? '' : `/${language}`
    const uri = `${addTrailingSlash(localizedRouteProjects)}${params.length ? '?' : ''}${params.join('&')}`
    window.history.replaceState({}, '', `${prefix}${uri}`);
    
    replaceRouteFromHistory({ path: uri, name: pageContext.routeName })
  }, [replaceRouteFromHistory, language, localizedRouteProjects, searchKeywords, yearFilter, industryFilter, pageContext.routeName])

  const handleChangeSearch = useCallback((searchValue) => {
    setSearchKeywords(searchValue)
  }, [])

  const handleChangeYear = useCallback((year) => {
    if (!$hasRunEntryAnimation.current) {
      setYearFilter(year)
      return
    }

    animateHideList({
      onComplete: () => {
        setYearFilter(year)
        animateShowList({
          onComplete: () => {}
        })
      }
    })
  }, []);

  const handleChangeIndustry = useCallback((industry) => {
    if (!$hasRunEntryAnimation.current) {
      setIndustryFilter(industry)
      return
    }
    animateHideList({
      onComplete: () => {
        setIndustryFilter(industry)
        animateShowList({
          onComplete: () => {}
        })
      }
    })
  }, []);

  // HOOKS
  useEffect(() => {
    setEntry()
    ScrollTrigger.clearScrollMemory()
  }, [])


  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setVisibleEntry()
      return
    }
  }, [animationState.isReady, animationState.canStart])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    const tls = animateEntry()

    $hasRunEntryAnimation.current = true
    $hasRunAnimation.current = true

    return () => {
      tls.forEach((tl) => tl && tl.kill())
    }
  }, [animationState.canPlay])

  useEffect(() => {
    if (yearFilter || industryFilter || searchKeywords || !searchKeywords.length) {
      updateLocation()
    }
  }, [updateLocation, yearFilter, industryFilter, searchKeywords])

  const throttledHandleChangeSearch = useMemo(() => throttle(handleChangeSearch, 300, true), [handleChangeSearch])

  return (
    <ClassicLayout
      name={pageContext.routeName}
      language={language}
      uri={props.uri}
      seo={{
        title: t('projects:seo:title'),
        description: t('projects:seo:description'),
      }}
      internationalRoute={pageContext.internationalRoute}
    >
      <div className="container container-wide">
        <h1 className="tpl-projects__title h4 ft-500 ft-no-select" aria-label={t('projects:hero:title')}>{t('projects:hero:title')}</h1>
        <SearchProjects
          isUnlocked={canShowNDA}
          defaultSearch={searchKeywords}
          defaultYear={yearFilter}
          defaultIndustry={industryFilter}
          onChangeSearch={throttledHandleChangeSearch}
          industries={industries}
          onFilterYear={handleChangeYear}
          onFilterIndustry={handleChangeIndustry}
        />
        <div className="list">
          {filteredProjectsByYears.length ? filteredProjectsByYears.map((item, i) =>  (
            <div key={i}>
              <h2 className={`tpl-projects__year year mb-3 mb-md-5 ft-400 c-alpha-5 ${i !== 0 ? 'mt-8 mt-b-md-1' : ''}`} aria-label={item.year}>
                { item.year.split('').map((y, k) => <span aria-hidden={true} key={`${y}-${k}`}>{y}</span>)}
              </h2>
              <ListProjects canShowNDA={canShowNDA} projects={item.filteredProjects} disableAnimations animation={listProjectsAnimation} />
            </div>)
          ) : (
            <div className="search__not-found" >
              <span className="c-gray-600">{t('projects:search:not_found')}{searchKeywords ? ` ${t('projects:search:for')} ` : ''}</span>
              {searchKeywords && <span className="search__keywords c-white">"{searchKeywords}"</span>}
            </div>)}
        </div>
      </div>
    </ClassicLayout>
  )
}

export default Projects

export const query = graphql`
  query($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: {eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismicProjects: allPrismicProject(
      sort: { order: [DESC, DESC], fields: [data___year, data___month] }
      filter: { lang: { eq: $contentLang } }
    ) {
      ...prismicProject
    }
  }
`
