import React, { memo, useContext } from 'react'
import { internationalRoutes, routeNames } from '../../utils/helpers/routes'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import ListItemProject from './ListItemProject'
import { I18nextContext } from 'gatsby-plugin-react-i18next'

const ListProjects = (props) => {
  const { projects, light, canShowNDA } = props

  const { language } = useContext(I18nextContext)

  return (
    <ul className={`list--project ${light ? 'list--project-light' : ''}`}>
      {projects.map((project) => {
        const { client } = project
        const localizedRouteProject =
          internationalRoutes[
            project.has_nda ? routeNames.lockedProjects : routeNames.projects
          ].langs[language]

        if (
          ((project.has_nda && canShowNDA) || !project.has_nda) &&
          project.has_page
        ) {
          return (
            <li key={project.uid}>
              <LocalizedTransitionLink
                className="list__item"
                entryTransitionType="slideIn"
                exitTransitionType="none"
                to={`${localizedRouteProject}/${project.uid}`}
                role="button"
                tabIndex={0}
              >
                <ListItemProject
                  isUnlocked={true}
                  hasNda={project.has_nda}
                  name={project.name?.text}
                  clientName={client?.name?.text}
                  tags={project.tags}
                />
              </LocalizedTransitionLink>
              <span className="list__border"></span>
            </li>
          )
        }
        return (
          <li key={project.uid}>
            <div className="list__item list__item--not-openable">
              <ListItemProject
                hasNda
                name={project.name?.text}
                clientName={client?.name?.text}
                tags={project.tags}
              />
            </div>
            <span className="list__border"></span>
          </li>
        )
      })}
    </ul>
  )
}
export default memo(ListProjects)
