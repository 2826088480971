import gsap from "gsap"
import { easeOutBack, easeOutQuad } from '../../utils/constants/eases'
import { gsapParams } from "./common"

const selectors = {
  list: '.list--project',
  listItems: '.list--project li',
  pageTitle: '.tpl-projects__title',
  filter: '.filter',
  searchNotFound: '.search__not-found'
}

const setYearForEntry = (show) => {
  if (!document.querySelector('.year')) {
    return
  }
  gsap.set('.year span', show ? { opacity: 1, translateY: 0} : { opacity: 0, translateY: 15 })
}

const setTitleForEntry = (show) => {
  const title = document.querySelector(selectors.pageTitle)
  const filter = document.querySelector(selectors.filter)

  if (title) {
    gsap.set(title, show ?  { ...gsapParams.fadeInUp } : { ...gsapParams.fadeOutDown30 })
  }
  
  if (filter) {
    gsap.set(filter, show ? { ...gsapParams.fadeInUp } : { ...gsapParams.fadeOutDown30 })
  }
}

const animateYear = (delay = 0) => {
  if (!document.querySelector('.year')) {
    return
  }

  const tl = gsap.timeline()
  tl.to('.year span', { opacity: 1, translateY: 0, duration: .5, stagger: 0.05, ease: easeOutBack }, delay)

  return tl
}

const animateHideList = ( props ) => {
  const onComplete = props && props.onComplete ? props.onComplete  : () => {}
  const tl = gsap.timeline({ onComplete })
  tl.to('.list', { pointerEvents: 'none', ease: 'none', duration: 0}, 0)
  tl.to('.list', { 
    opacity: 0, 
    duration: .3,
    ease: easeOutQuad
  })

  return tl
}

const animateShowList = ( props ) => {
  const onComplete = props && props.onComplete ? props.onComplete  : () => {}
  const tl = gsap.timeline({ onComplete })

  animateYear()

  tl.to('.list', { pointerEvents: 'all', ease: 'none', duration: 0 }, 0)
  tl.to('.list', { opacity: 1, duration: .3, ease: easeOutQuad })
  
  if (document.querySelector(selectors.searchNotFound)) {
    tl.to(selectors.searchNotFound, { translateY: 10, opacity: 0, duration: 0, ease: 'none' }, 0)
    tl.to(selectors.searchNotFound, { translateY: 0, opacity: 1, duration: .3, ease: easeOutBack  }, .15)
  }
  return tl
}

const animateTitle = () => {
  const tl = gsap.timeline()
  tl.to(selectors.pageTitle, { ...gsapParams.fadeInUp, duration: .5 }, .15)
  tl.to(selectors.filter, { ...gsapParams.fadeInUp, duration: .5 }, .15)

  return tl
}

const animateListItemEntry = (l, delay = 0) => {
  const tl = gsap.timeline()
  const listItems = l.querySelectorAll(selectors.listItems)
  tl.to(listItems, { ...gsapParams.fadeIn, ease: easeOutQuad, duration: .5, stagger: 0.025 }, delay)
  return tl
}

const setListForEntry = (show) => {
  gsap.set(selectors.listItems, show ? { ...gsapParams.fadeIn } : { ...gsapParams.fadeOut })
}

const setEntry = () => {
  setYearForEntry()
  setTitleForEntry()
  setListForEntry()
}

const setVisibleEntry = () => {
  setYearForEntry(true)
  setTitleForEntry(true)
  setListForEntry(true)
}

const animateEntry = () => {
  const tl = []
  tl.push(animateTitle())
  tl.push(animateYear(.4))

  const lists = document.querySelectorAll(selectors.list)
  lists.forEach((l) => tl.push(animateListItemEntry(l, .5)))
  

  return tl
}

export {
  animateEntry,
  animateListItemEntry,
  animateHideList,
  animateShowList,
  setEntry,
  setVisibleEntry
}