import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react'
import { iconCross } from '../../utils/icons'
import { useTranslation } from 'react-i18next'
import gsap from 'gsap'

const SearchProjects = ({
  isUnlocked,
  defaultSearch,
  defaultIndustry,
  defaultYear,
  industries,
  onChangeSearch,
  onFilterYear,
  onFilterIndustry,
}) => {
  const { t } = useTranslation(['projects'])
  const [searchKeywords, setSearchKeywords] = useState(defaultSearch)
  const [isFilterListOpen, setIsFilterListOpen] = useState(false)

  const [yearFilter, setYearFilter] = useState(defaultYear)
  const [currentFilter, setCurrentFilter] = useState('')
  const [industryFilter, setIndustryFilter] = useState(defaultIndustry)

  const $filterList = useRef()
  const $filterSearch = useRef()

  const years = useMemo(
    () => ['2021', '2020', '2019', '2018', '2017', '2016', '2015'],
    []
  )

  const closeFilterList = useCallback(() => {
    setIsFilterListOpen(false)
  }, [])

  const filterByKeywords = useCallback((value) => setSearchKeywords(value), [])

  const filterByIndustry = useCallback(
    (industry) =>
      setIndustryFilter((prevState) =>
        industry === prevState ? '' : industry
      ),
    []
  )

  const filterByYear = useCallback(
    (year) => setYearFilter((prevState) => (year === prevState ? '' : year)),
    []
  )

  const handleFocusSearch = useCallback(() => {
    setIsFilterListOpen(false)
    gsap.set($filterList.current, { opacity: 0 })
    gsap.set($filterSearch.current, { minWidth: '100%' })
  }, [])

  const handleBlurSearch = useCallback(() => {
    gsap.set($filterSearch.current, { minWidth: 0 })
    gsap.set($filterList.current, { opacity: 1 })
  }, [])

  const handleOpenFilter = useCallback(() => {
    setIsFilterListOpen((prevState) => !prevState)
  }, [])

  const resetFilters = useCallback(
    (type) => {
      switch (type) {
        case 'search':
          filterByKeywords('')
          break
        case 'year':
          filterByYear('')
          break
        case 'all-but-search':
          setCurrentFilter('')
          filterByIndustry('')
          filterByYear('')
          break
        case 'all-but-year':
          filterByIndustry('')
          filterByKeywords('')
          break
        case 'all-but-industry':
          filterByYear('')
          filterByKeywords('')
          break
        default:
          filterByKeywords('')
          filterByIndustry('')
          filterByYear('')
          break
      }
    },
    [filterByKeywords, filterByYear, filterByIndustry]
  )

  const handleResetSearch = useCallback(() => resetFilters(), [resetFilters])

  const handleOnClickResetFilters = useCallback(() => {
    resetFilters()
    closeFilterList()
    setCurrentFilter('')
  }, [resetFilters, closeFilterList])

  const handleOnClickIndustry = useCallback(
    (industryUid, industryName) => {
      resetFilters('all-but-industry')
      filterByIndustry(industryUid)
      setCurrentFilter(industryName)
      closeFilterList()
    },
    [resetFilters, filterByIndustry, closeFilterList]
  )

  const handleOnClickYear = useCallback(
    (year) => {
      resetFilters('all-but-year')
      filterByYear(year)
      setCurrentFilter(year)
      closeFilterList()
    },
    [resetFilters, closeFilterList, filterByYear]
  )

  const handleOnChangeSearch = useCallback(
    (e) => {
      resetFilters('all-but-search')
      filterByKeywords(e.currentTarget.value)
    },
    [resetFilters, filterByKeywords]
  )

  const hasYearFilter = useMemo(() => yearFilter.length > 0, [yearFilter])
  const hasIndustryFilter = useMemo(
    () => industryFilter.length > 0,
    [industryFilter]
  )

  useEffect(() => {
    onChangeSearch(searchKeywords)
  }, [onChangeSearch, searchKeywords])

  useEffect(() => {
    onFilterYear(yearFilter)
  }, [onFilterYear, yearFilter])

  useEffect(() => {
    onFilterIndustry(industryFilter)
  }, [onFilterIndustry, industryFilter])

  useEffect(() => {
    if (!defaultYear.length) {
      return
    }
    setCurrentFilter(defaultYear)
  }, [defaultYear])

  useEffect(() => {
    if (!defaultIndustry.length) {
      return
    }
    const industry = industries.find(
      (industry) => industry.uid === defaultIndustry
    )
    if (!industry) return
    setCurrentFilter(industry.name)
  }, [industries, defaultIndustry])

  return (
    <div
      className={`row mt-3 mt-md-8 mb-6 mb-md-7 f--desktop filter ${
        isFilterListOpen ? ' is-opened' : ''
      }`}
    >
      <div className={`col input col-lg-3`} ref={$filterSearch}>
        <div className={`search__wrapper`}>
          <input
            type="text"
            className={`search ${
              searchKeywords.length > 0 ? 'is-active' : ''
            } ft-truncat`}
            value={searchKeywords}
            onChange={handleOnChangeSearch}
            onFocus={handleFocusSearch}
            onBlur={handleBlurSearch}
            placeholder={t('projects:search:input')}
          />
          {searchKeywords.length > 0 && (
            <button className="search__delete" onClick={handleResetSearch}>
              {iconCross}
            </button>
          )}
        </div>
      </div>
      <div
        className={`col col-lg-offset-1 mb-0 mt-7 mb-lg-0 mt-lg-0 col-lg-9`}
        ref={$filterList}
      >
        <div className="filter__header">
          <div className="filter__left f--tablet f-ai-center">
            <div
              role="button"
              onClick={handleOpenFilter}
              onKeyDown={handleOpenFilter}
              tabIndex={0}
            >
              {t('projects:search:filters:title')}
            </div>
            <div
              className={`filter__select ${
                currentFilter.length > 0 ? '' : 'hide-visibility'
              }`}
            >
              <span className="tag tag--small tag--ai-centered">
                {currentFilter.length > 0 ? currentFilter : '&nbsp;'}{' '}
                <button
                  aria-label={t('projects:search:filters:remove', {
                    filter: currentFilter,
                  })}
                  onClick={handleOnClickResetFilters}
                  className="c-white ml-2 f f-ai-center c-alpha-5"
                >
                  {iconCross}
                </button>
              </span>
            </div>
          </div>
          <div className="filter__right f-inline f-direction-column">
            <div className="filter__cta">
              <button
                className="filter__link link link-color ft-secondary filter__toggle"
                onClick={handleOpenFilter}
              >
                <span className="">{t('projects:search:filters:open')}</span>
                <span className="">{t('projects:search:filters:close')}</span>
              </button>
              {/* {isUnlocked ? (
                <LocalizedTransitionLink
                  className="link link-color"
                  to={localizedRoutes[routeNames.projects]}
                >
                  {t('projects:search:lock')}
                </LocalizedTransitionLink>
              ) : (
                <a
                  className="filter__link filter__unlock link link-color"
                  href={localizedLockedProjectRoute}
                  rel="noopener noreferrer"
                >
                  {t('projects:search:unlock')}
                </a>
              )} */}
            </div>
            <button
              className="filter__link link link-color ft-secondary filter__clear"
              onClick={handleOnClickResetFilters}
            >
              {t('projects:search:filters:clear')}
            </button>
          </div>
        </div>
        <div className="filter__content">
          <div className="mt-3 mt-lg-8 filter__body">
            <button
              className={`ft-default-m-small c-white ${
                hasYearFilter ? 'c-alpha-5' : ''
              }`}
              onClick={handleOnClickResetFilters}
            >
              {t('projects:search:filters:years')}
            </button>
            <ul className="mosaic mosaic--3 mt-2 mt-lg-4 mosaic--lg-gap-24">
              {years.map((year) => (
                <li key={year}>
                  <button
                    className={`ft-default-m-small c-white ${
                      yearFilter === year ? '' : 'c-alpha-5'
                    }`}
                    onClick={() => handleOnClickYear(year)}
                  >
                    {year}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-4 mb-0 mt-lg-8 filter__body">
            <button
              className={`ft-default-m-small c-white ${
                hasIndustryFilter ? 'c-alpha-5' : ''
              }`}
              onClick={handleOnClickResetFilters}
            >
              {t('projects:search:filters:industries')}
            </button>
            <ul className="mosaic mosaic--3 mt-2 mt-lg-4 mosaic--lg-gap-24">
              {industries.map((item, index) => {
                const industry = item.name
                const uid = item.uid
                const count = item.count

                if (!uid || !industry) {
                  return null
                }

                return (
                  <li key={index}>
                    <button
                      className={`ft-left ft-default-m-small c-white ${
                        industryFilter === uid ? '' : 'c-alpha-5'
                      }`}
                      onClick={() => handleOnClickIndustry(uid, industry)}
                    >
                      {industry} ({count})
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(SearchProjects)
